var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useRef } from 'react';
import { kebabCase } from 'lodash';
import { Button, TextField, Fields } from '../Form';
import { AbsoluteOverlay } from '../Indicators';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDefaultValueListener, } from '@sumocreations/forms';
import { camelCase } from 'lodash';
import { ErrorList } from '../Form/ErrorList';
import { AttachmentList, TextAreaField, MimeTypes, } from '../Form';
var schema = yup.object({
    title: yup.string().required('cannot be blank'),
    slug: yup.string().required('cannot be blank'),
    fileKey: yup.string().required('cannot be blank'),
    keywords: yup.string(),
    description: yup.string(),
});
export var DocumentForm = function (_a) {
    var externalSubmitHandler = _a.onSubmit, loading = _a.loading, defaultValues = _a.defaultValues, submitTitle = _a.submitTitle, handleEndpoints = _a.onRequestEndpoint, handleAttachedFile = _a.onAttachFile, previews = _a.previews;
    var _b = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur',
    }), handleSubmit = _b.handleSubmit, register = _b.register, formErrors = _b.formState.errors, setError = _b.setError, setValue = _b.setValue, reset = _b.reset, watch = _b.watch;
    var _c = watch(['slug', 'fileKey']), slug = _c[0], fileKey = _c[1];
    useEffect(function () {
        var formatted = kebabCase(slug);
        if ((slug === null || slug === void 0 ? void 0 : slug.substr(-1)) === ' ') {
            return;
        }
        if (formatted && formatted.length > 0 && formatted !== slug) {
            setValue('slug', formatted);
        }
    }, [slug, setValue]);
    useDefaultValueListener(defaultValues, reset);
    var handleFormSubmit = handleSubmit(function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, errors, keys;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, externalSubmitHandler(data)];
                case 1:
                    _a = ((_b = (_c.sent())) !== null && _b !== void 0 ? _b : {}).errors, errors = _a === void 0 ? {} : _a;
                    keys = Object.keys(errors);
                    if (keys.length) {
                        keys.map(function (key) {
                            return setError(camelCase(key), {
                                message: errors[key],
                            });
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); });
    var field = useRef(null);
    useEffect(function () {
        var _a;
        (_a = field.current) === null || _a === void 0 ? void 0 : _a.focus();
    });
    var handleDeletedFile = function () {
        setValue('fileKey', undefined);
    };
    var handleUploadedFile = function (file) {
        setValue('fileKey', file.id);
    };
    return (React.createElement("form", { onSubmit: handleFormSubmit, className: "relative" },
        React.createElement(Fields, { register: register, errors: formErrors, grow: true, className: "pb-2" },
            React.createElement(TextField, { name: "title", label: "Title", placeholder: "Document Title", ref: field, className: "w-full" }),
            React.createElement(TextField, { name: "slug", label: "Slug", placeholder: "url-slug", type: "text", className: "w-full" }),
            React.createElement(TextAreaField, { name: "keywords", label: "Keywords", placeholder: "any, keywords, used, for, seo, purporses", className: "w-full" }),
            React.createElement(TextAreaField, { name: "description", label: "Description", placeholder: "A page description used for SEO purposes.", className: "w-full" }),
            React.createElement(AttachmentList, { getEndpointsForFilenames: handleEndpoints, onUpdate: handleAttachedFile, maxFiles: 1, previews: previews, accept: MimeTypes.PDF, onUpload: handleUploadedFile, onDelete: handleDeletedFile }),
            React.createElement("input", { type: "hidden", name: "fileKey", value: fileKey })),
        React.createElement(ErrorList, { errors: formErrors }),
        React.createElement(Button, { type: "submit", className: "mt-2 w-full" }, submitTitle !== null && submitTitle !== void 0 ? submitTitle : 'Save Document'),
        loading ? React.createElement(AbsoluteOverlay, null) : null));
};
