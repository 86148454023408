var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
// import { media } from '../media'
import { transparentize } from 'polished';
import styled from '../styled-components';
export var DividerStyle;
(function (DividerStyle) {
    DividerStyle[DividerStyle["Clear"] = 0] = "Clear";
    DividerStyle[DividerStyle["Border"] = 1] = "Border";
})(DividerStyle || (DividerStyle = {}));
export var DividerSize;
(function (DividerSize) {
    DividerSize[DividerSize["Standard"] = 0] = "Standard";
    DividerSize[DividerSize["Expand"] = 1] = "Expand";
})(DividerSize || (DividerSize = {}));
export var Divider = styled.hr(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  border: none;\n  border-top: 1px solid\n    ", ";\n  flex-grow: ", ";\n  margin: 0.5rem 0;\n"], ["\n  display: flex;\n  border: none;\n  border-top: 1px solid\n    ", ";\n  flex-grow: ", ";\n  margin: 0.5rem 0;\n"])), function (p) {
    return p.style === DividerStyle.Border
        ? transparentize(0.7)(p.theme.colorSecondary)
        : 'transparent';
}, function (p) { return (p.size === DividerSize.Expand ? 1 : 0); });
var templateObject_1;
