var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { transparentize } from 'polished';
import { Flex } from 'rebass';
import * as Errors from '../Errors';
import * as Grid from '../Grid';
import * as Heading from '../Heading';
import * as Indicators from '../Indicators';
import styled from '../styled-components';
export var View = styled.article(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  border: 1px solid ", ";\n  border-radius: ", ";\n  display: flex;\n  flex-direction: column;\n  padding: 18px;\n  width: auto;\n  flex-grow: 1;\n  box-shadow: 0 0 36px rgba(0, 0, 0, 0.3);\n  position: relative;\n\n  & + & {\n    margin-top: 18px;\n  }\n"], ["\n  background: ", ";\n  border: 1px solid ", ";\n  border-radius: ", ";\n  display: flex;\n  flex-direction: column;\n  padding: 18px;\n  width: auto;\n  flex-grow: 1;\n  box-shadow: 0 0 36px rgba(0, 0, 0, 0.3);\n  position: relative;\n\n  & + & {\n    margin-top: 18px;\n  }\n"])), function (p) { return p.theme.colorContentAreaBackground; }, function (p) { return p.theme.colorContentAreaBorder; }, function (p) { return p.theme.borderRadius; });
export var Header = styled.header(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: sticky;\n  top: 0;\n  left: 0;\n  margin-top: -1rem;\n  padding-top: 1rem;\n  background: ", ";\n  z-index: 10;\n"], ["\n  position: sticky;\n  top: 0;\n  left: 0;\n  margin-top: -1rem;\n  padding-top: 1rem;\n  background: ", ";\n  z-index: 10;\n"])), function (p) { return transparentize(0.2)(p.theme.colorContentAreaBackground); });
export var Flat = styled(View)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  box-shadow: none;\n  border: 2px solid ", ";\n"], ["\n  box-shadow: none;\n  border: 2px solid ", ";\n"])), function (p) { return p.theme.colorContentAreaBorder; });
export var Border = styled(View)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  box-shadow: none;\n  border: 1px solid ", ";\n  padding: 8px;\n"], ["\n  box-shadow: none;\n  border: 1px solid ", ";\n  padding: 8px;\n"])), function (p) { return p.theme.colorContentAreaBorder; });
export var CenteredSection = styled(Flex)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-content: center;\n  margin: 0 auto;\n"], ["\n  display: flex;\n  align-content: center;\n  margin: 0 auto;\n"])));
export var Padding = styled.p(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  margin: 0 auto;\n  padding: 12px 0;\n"], ["\n  display: flex;\n  margin: 0 auto;\n  padding: 12px 0;\n"])));
export var Standard = function (_a) {
    var children = _a.children, loading = _a.loading, error = _a.error, title = _a.title;
    return (React.createElement(Flat, null,
        React.createElement(Header, null,
            React.createElement(Grid.Row, null,
                React.createElement(Grid.Col, { span: 4 }, !title ? (React.createElement(Heading.Title, null, "Loading")) : (React.createElement(Heading.Title, null, title))))),
        React.createElement(Grid.Row, null,
            React.createElement(Grid.Col, { span: 16 }, loading ? (React.createElement(Indicators.Spinner, null)) : error ? (React.createElement(Errors.LoadingError, { error: error })) : (children)))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
