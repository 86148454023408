var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { transparentize } from 'polished';
import * as React from 'react';
import styled from '../styled-components';
var TableView = styled.table(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-spacing: 0;\n  width: 100%;\n"], ["\n  border-spacing: 0;\n  width: 100%;\n"])));
var Header = styled.thead(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
var TH = styled.th(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: 400;\n  font-size: 0.875rem;\n  border-top: 0;\n  padding: 0.5rem;\n  text-align: ", ";\n  width: ", ";\n"], ["\n  font-weight: 400;\n  font-size: 0.875rem;\n  border-top: 0;\n  padding: 0.5rem;\n  text-align: ", ";\n  width: ", ";\n"])), function (p) { return (p.contentType === 'numeric' ? 'right' : 'left'); }, function (p) { return p.width; });
var HeaderCell = styled(TH)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: 400;\n  font-size: 0.875rem;\n  color: ", ";\n  border-bottom: 0.1rem solid\n    ", ";\n"], ["\n  font-weight: 400;\n  font-size: 0.875rem;\n  color: ", ";\n  border-bottom: 0.1rem solid\n    ", ";\n"])), function (p) { return p.theme.colorPrimary; }, function (p) { return transparentize(0.8)(p.theme.colorPrimary); });
var TotalCell = styled(TH)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: 600;\n  background: ", ";\n  border-bottom: 0.1rem solid\n    ", ";\n"], ["\n  font-weight: 600;\n  background: ", ";\n  border-bottom: 0.1rem solid\n    ", ";\n"])), function (p) { return transparentize(0.95)(p.theme.colorPrimary); }, function (p) { return transparentize(0.875)(p.theme.colorPrimary); });
var Cell = styled.td(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-weight: 400;\n  font-size: 0.875rem;\n  color: ", ";\n  border-bottom: 0.1rem solid\n    ", ";\n  border-top: 0;\n  padding: 0.5rem;\n  text-align: ", ";\n  width: ", ";\n"], ["\n  font-weight: 400;\n  font-size: 0.875rem;\n  color: ", ";\n  border-bottom: 0.1rem solid\n    ", ";\n  border-top: 0;\n  padding: 0.5rem;\n  text-align: ", ";\n  width: ", ";\n"])), function (p) { return p.theme.colorPrimary; }, function (p) { return transparentize(0.95)(p.theme.colorPrimary); }, function (p) { return (p.contentType === 'numeric' ? 'right' : 'left'); }, function (p) { return p.width; });
var DataTableHeader = function (p) { return (React.createElement(Header, null,
    React.createElement("tr", null, p.headings &&
        p.headings.map(function (heading, index) { return (React.createElement(HeaderCell, { key: "heading-" + index, contentType: p.columnContentTypes[index], width: p.widths ? p.widths[index] : 1 / (p.headings || []).length + "%" }, heading)); })),
    p.totals && (React.createElement("tr", null, p.totals.map(function (total, index) { return (React.createElement(TotalCell, { key: "total-" + total + "-" + index, contentType: p.columnContentTypes[index], width: p.widths ? p.widths[index] : 1 / (p.headings || []).length + "%" }, index === 0 && total === '' ? 'Totals' : total)); }))))); };
export var Table = function (p) { return (React.createElement(TableView, null,
    p.headings && React.createElement(DataTableHeader, __assign({}, p)),
    React.createElement("tbody", null, p.rows.map(function (row, y) { return (React.createElement("tr", { key: "row-" + y }, row.map(function (val, x) { return (React.createElement(Cell, { key: "cell-" + x + "-" + y, contentType: p.columnContentTypes[x], width: p.widths ? p.widths[x] : 1 / row.length + "%" }, val)); }))); })))); };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
