var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useRef } from 'react';
import { Button, TextField, Fields, SelectField } from '../Form';
import { AbsoluteOverlay } from '../Indicators';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDefaultValueListener, } from '@sumocreations/forms';
import { camelCase } from 'lodash';
import { ErrorList } from '../Form/ErrorList';
var schema = yup.object({
    modelNumber: yup.string().required('cannot be blank'),
    serial: yup.string().required('cannot be blank'),
    purchaseDate: yup.string().required('cannot be blank'),
    purchasedFrom: yup.string().required('cannot be blank'),
    name: yup.string().required('cannot be blank'),
    email: yup.string().email().required('cannot be blank'),
    phone: yup.string().required('cannot be blank'),
    street: yup.string().required('cannot be blank'),
    street2: yup.string(),
    zip: yup.string().required('cannot be blank'),
    city: yup.string().required('cannot be blank'),
    state: yup.string().required('cannot be blank'),
    country: yup.string().required('cannot be blank'),
});
export var RegisterForm = function (_a) {
    var externalSubmitHandler = _a.onSubmit, loading = _a.loading, defaultValues = _a.defaultValues, submitTitle = _a.submitTitle, modelOptions = _a.modelOptions, _b = _a.customerHeading, customerHeading = _b === void 0 ? 'Tell us about you:' : _b, _c = _a.purchaseHeading, purchaseHeading = _c === void 0 ? "Tell us about the product you're registering:" : _c, _d = _a.disableCustomerFields, disableCustomerFields = _d === void 0 ? false : _d;
    var _e = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur',
    }), handleSubmit = _e.handleSubmit, register = _e.register, formErrors = _e.formState.errors, setError = _e.setError, setValue = _e.setValue, watch = _e.watch, reset = _e.reset;
    var modelNumber = watch(['modelNumber'])[0];
    useDefaultValueListener(defaultValues, reset);
    var handleFormSubmit = handleSubmit(function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, errors, keys;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, externalSubmitHandler(data)];
                case 1:
                    _a = ((_b = (_c.sent())) !== null && _b !== void 0 ? _b : {}).errors, errors = _a === void 0 ? {} : _a;
                    keys = Object.keys(errors);
                    if (keys.length) {
                        keys.map(function (key) {
                            return setError(camelCase(key), {
                                message: errors[key],
                            });
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); });
    var field = useRef(null);
    useEffect(function () {
        var _a;
        (_a = field.current) === null || _a === void 0 ? void 0 : _a.focus();
    });
    var onSelectModel = function (id) {
        setValue('modelNumber', id !== null && id !== void 0 ? id : '');
    };
    return (React.createElement("form", { onSubmit: handleFormSubmit, className: "relative" },
        React.createElement(Fields, { register: register, errors: formErrors, grow: true, className: "pb-2" },
            React.createElement("h3", { className: "text-md font-bold mt-4" }, customerHeading),
            React.createElement(TextField, { name: "name", label: "Name", placeholder: "Full Name", ref: field, className: "w-full", disabled: disableCustomerFields }),
            React.createElement(TextField, { name: "email", label: "Email Address", placeholder: "address@example.com", ref: field, className: "w-full", disabled: disableCustomerFields }),
            React.createElement(TextField, { name: "street", label: "Address", placeholder: "Street Address", className: "w-full" }),
            React.createElement(TextField, { name: "street2", label: "Address Line 2", placeholder: "(optional) i.e. unit number", className: "w-full" }),
            React.createElement(Fields, { register: register, nested: true, grow: true },
                React.createElement(TextField, { name: "city", label: "City", placeholder: "City name", className: "w-full" }),
                React.createElement(TextField, { name: "state", label: "State", placeholder: "State", className: "w-full" })),
            React.createElement(Fields, { register: register, nested: true, grow: true },
                React.createElement(TextField, { name: "zip", label: "Zip Code", placeholder: "Zip Code", className: "w-full" }),
                React.createElement(TextField, { name: "country", label: "Country", placeholder: "Country name", className: "w-full" })),
            React.createElement(TextField, { name: "phone", label: "Phone Number", className: "w-full" })),
        React.createElement("h3", { className: "text-md font-bold mt-4" }, purchaseHeading),
        React.createElement(SelectField, { name: "modelNumber", label: "Model Number", className: "w-full", options: modelOptions, onSelect: onSelectModel, value: modelNumber !== null && modelNumber !== void 0 ? modelNumber : '' }),
        React.createElement(Fields, { register: register, nested: true, grow: true },
            React.createElement(TextField, { name: "serial", label: "Serial Number", className: "w-full" }),
            React.createElement(TextField, { name: "purchaseDate", label: "Date Purchased", className: "w-full", type: "date" }),
            React.createElement(TextField, { name: "purchasedFrom", label: "Where did you purchase this product?", className: "w-full", placeholder: "Vendor or store name." })),
        React.createElement(ErrorList, { errors: formErrors }),
        React.createElement(Button, { type: "submit", className: "mt-2 w-full" }, submitTitle !== null && submitTitle !== void 0 ? submitTitle : 'Register Product'),
        loading ? React.createElement(AbsoluteOverlay, null) : null));
};
