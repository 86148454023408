var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { transparentize } from 'polished';
import * as React from 'react';
import styled from '../styled-components';
var Container = styled.p(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  position: relative;\n  display: flex;\n  width: 4rem;\n  height: 1.75rem;\n  border-radius: 0.875rem;\n  margin: auto 0;\n"], ["\n  background: ", ";\n  position: relative;\n  display: flex;\n  width: 4rem;\n  height: 1.75rem;\n  border-radius: 0.875rem;\n  margin: auto 0;\n"])), function (p) {
    return p.on
        ? p.theme.colorButtonPrimary
        : transparentize(0.5)(p.theme.colorPrimary);
});
var Button = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: 0;\n  color: #fff;\n  font-size: 0.825rem;\n  cursor: pointer;\n  display: flex;\n  position: absolute;\n  background: transparent;\n  opacity: 1;\n  top: 0;\n  left: 0;\n  height: 100%;\n  width: 100%;\n\n  span {\n    margin: auto;\n    text-align: center;\n    transform: translate3d(", "rem, 0, 0);\n    transition: 0.25s transform ease-out;\n    text-transform: uppercase;\n  }\n"], ["\n  border: 0;\n  color: #fff;\n  font-size: 0.825rem;\n  cursor: pointer;\n  display: flex;\n  position: absolute;\n  background: transparent;\n  opacity: 1;\n  top: 0;\n  left: 0;\n  height: 100%;\n  width: 100%;\n\n  span {\n    margin: auto;\n    text-align: center;\n    transform: translate3d(", "rem, 0, 0);\n    transition: 0.25s transform ease-out;\n    text-transform: uppercase;\n  }\n"])), function (p) { return (p.on ? 0.75 : -0.75); });
var Indicator = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: ", ";\n  display: block;\n  height: 1.75rem;\n  width: 1.75rem;\n  border-radius: 0.875rem;\n  box-shadow: 1px 0 4px ", ";\n  transform: translate3d(", "rem, 0, 0);\n  transition: 0.25s transform ease-out;\n  pointer-events: none;\n"], ["\n  background: ", ";\n  display: block;\n  height: 1.75rem;\n  width: 1.75rem;\n  border-radius: 0.875rem;\n  box-shadow: 1px 0 4px ", ";\n  transform: translate3d(", "rem, 0, 0);\n  transition: 0.25s transform ease-out;\n  pointer-events: none;\n"])), function (p) { return p.theme.colorContentAreaBackground; }, function (p) { return p.theme.colorPrimary; }, function (p) { return (p.on ? 0 : 2.25); });
export var View = function (_a) {
    var labels = _a.labels, on = _a.on, onClick = _a.onClick;
    var displayLabel = labels || ['on', 'off'];
    return (React.createElement(Container, { on: on },
        React.createElement(Button, { onClick: onClick, type: "button", on: on },
            React.createElement("span", null, on ? displayLabel[0] : displayLabel[1])),
        React.createElement(Indicator, { on: on })));
};
var templateObject_1, templateObject_2, templateObject_3;
