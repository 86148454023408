import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons/faSpinnerThird";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
var SPINNER_CLASS = "flex flex-grow-0 flex-shrink";
export var Spinner = function (_a) {
    var className = _a.className, _b = _a.size, size = _b === void 0 ? "1x" : _b;
    return (React.createElement("p", { className: [className !== null && className !== void 0 ? className : "", SPINNER_CLASS].join(" ") },
        React.createElement("span", { className: "animate-spin" },
            React.createElement(FontAwesomeIcon, { icon: faSpinnerThird, size: size }))));
};
