var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { transparentize } from 'polished';
import { media } from '../media';
import styled from '../styled-components';
export var Size;
(function (Size) {
    Size[Size["default"] = 748] = "default";
    Size[Size["medium"] = 512] = "medium";
    Size[Size["small"] = 384] = "small";
})(Size || (Size = {}));
export var Container = styled.article(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: ", ";\n  display: flex;\n  position: fixed;\n  top: 0;\n  left: 0;\n  height: 100vh;\n  width: 100vw;\n  margin: 0;\n  overflow: auto;\n\n  ", "\n"], ["\n  background: ", ";\n  display: flex;\n  position: fixed;\n  top: 0;\n  left: 0;\n  height: 100vh;\n  width: 100vw;\n  margin: 0;\n  overflow: auto;\n\n  ", "\n"])), function (p) {
    return transparentize(p.transparent ? 0.5 : 0)(p.theme.colorPrimary);
}, media.minSm(templateObject_1 || (templateObject_1 = __makeTemplateObject(["align-items: center;"], ["align-items: center;"]))));
export var Heading = styled.header(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  margin: 15px auto;\n  color: ", ";\n  & > * {\n    margin: 0 10px;\n  }\n"], ["\n  display: flex;\n  margin: 15px auto;\n  color: ", ";\n  & > * {\n    margin: 0 10px;\n  }\n"])), function (p) { return p.theme.colorBodyTextInverted; });
export var ContentRegion = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  position: relative;\n\n  ", "\n  ", "\n"], ["\n  display: flex;\n  flex-direction: column;\n  position: relative;\n\n  ", "\n  ", "\n"])), media.minXs(templateObject_4 || (templateObject_4 = __makeTemplateObject(["width: 100%; margin: 0;"], ["width: 100%; margin: 0;"]))), function (p) {
    return media.minSm(templateObject_5 || (templateObject_5 = __makeTemplateObject(["width: 74.33333%; max-width: ", "px; flex-basis: 74.33333%; margin: auto;"], ["width: 74.33333%; max-width: ", "px; flex-basis: 74.33333%; margin: auto;"])), p.size);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
