var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import Container from './Container';
import styled from './styled-components';
var ContentRegion = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  display: flex;\n  flex-grow: 1;\n  width: 100%;\n  margin: auto;\n"], ["\n  background: ", ";\n  display: flex;\n  flex-grow: 1;\n  width: 100%;\n  margin: auto;\n"])), function (p) { return p.theme.colorSecondary; });
var InternalContentArea = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 8px 20px;\n  font-family: ", ";\n"], ["\n  padding: 8px 20px;\n  font-family: ", ";\n"])), function (p) { return p.theme.fontFamilyBody; });
var TextFormContent = styled.article(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  max-width: 800px;\n  width: 100%;\n  margin: 0 auto;\n"], ["\n  max-width: 800px;\n  width: 100%;\n  margin: 0 auto;\n"])));
var ContentMainHeading = styled.h1(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 1.75em;\n  color: ", ";\n  text-align: center;\n  padding-bottom: 3px;\n  margin-bottom: 16px;\n  border-bottom: 1px solid ", ";\n"], ["\n  font-size: 1.75em;\n  color: ", ";\n  text-align: center;\n  padding-bottom: 3px;\n  margin-bottom: 16px;\n  border-bottom: 1px solid ", ";\n"])), function (p) { return p.theme.colorBodyText; }, function (p) { return p.theme.colorContentAreaBorder; });
var Content = function (_a) {
    var children = _a.children;
    return (React.createElement(ContentRegion, null,
        React.createElement(Container, null,
            React.createElement(InternalContentArea, null, children))));
};
export { TextFormContent, ContentMainHeading };
export default Content;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
