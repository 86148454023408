import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { Icon } from './Icon';
import { faChevronLeft, faChevronRight, } from '@fortawesome/pro-regular-svg-icons';
export var MiniCal = function (_a) {
    var _b;
    var initialDate = _a.initialDate, selectedDate = _a.selectedDate, handleSelectedDate = _a.onDateSelected, _c = _a.selectedDateFormat, selectedDateFormat = _c === void 0 ? 'YYYY-MM-DD' : _c;
    var _d = useState(0), increment = _d[0], setIncrement = _d[1];
    var handleIncrementClick = function (newIncrement) { return function (e) {
        e.preventDefault();
        setIncrement(increment + newIncrement);
    }; };
    useEffect(function () {
        setIncrement(0);
    }, [selectedDate, initialDate]);
    var originDate = DateTime.fromISO((_b = selectedDate !== null && selectedDate !== void 0 ? selectedDate : initialDate) !== null && _b !== void 0 ? _b : '');
    var monthStart = (originDate.isValid
        ? originDate
        : DateTime.local().toUTC())
        .startOf('month')
        .plus({ months: increment });
    var handleDateClick = function (date) { return function (e) {
        e.preventDefault();
        if (handleSelectedDate) {
            handleSelectedDate(date);
        }
    }; };
    return (React.createElement("div", { className: "p-2 px-4 h-full" },
        React.createElement("div", { className: "flex flex-col" },
            React.createElement("nav", { className: "flex mt-2 bg-white rounded px-2 mb-2" },
                React.createElement("button", { onClick: handleIncrementClick(-1), className: "p-2" },
                    React.createElement(Icon, { icon: faChevronLeft, mode: "secondary" })),
                React.createElement("h3", { className: "text-sm font-semibold flex-grow text-center m-auto" }, monthStart.toFormat('MMM, yyyy')),
                React.createElement("button", { onClick: handleIncrementClick(1), className: "p-2" },
                    React.createElement(Icon, { icon: faChevronRight, mode: "secondary" }))),
            new Array(6).fill('').map(function (_, w) {
                var start = monthStart.plus({ weeks: w }).startOf('week');
                return (React.createElement("div", { className: "grid grid-cols-7", key: "minical-week-" + w }, new Array(7).fill('').map(function (_, d) {
                    var day = start.plus({ days: d });
                    var inMonth = monthStart.month === day.month;
                    return (React.createElement("li", { className: "flex bg-gray-100 hover:bg-gray-300 transition duration-100 ease-out mb-1 ml-1 text-xs text-gray-" + (inMonth ? 800 : 400), key: w + "-" + d },
                        React.createElement("button", { className: "flex flex-grow p-1", onClick: handleDateClick(day.toFormat(selectedDateFormat)) },
                            React.createElement("span", { className: "m-auto" }, day.toFormat('D')))));
                })));
            }))));
};
