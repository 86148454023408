var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useRef, useState } from 'react';
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons';
import { MiniCal } from './MiniCal';
import { Field } from './Field';
import { Input } from './Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Overlay } from '../Overlay';
export var DateField = React.forwardRef(function (_a, forwardedRef) {
    var _b, _c;
    var _d = _a.alignCalendar, alignCalendar = _d === void 0 ? 'left' : _d, initialDate = _a.initialDate, value = _a.value, handleSelectedDate = _a.onDateChange, _e = _a.selectedDateFormat, selectedDateFormat = _e === void 0 ? 'YYYY-MM-DD' : _e, disabled = _a.disabled, error = _a.error, required = _a.required, label = _a.label, name = _a.name, placeholder = _a.placeholder, className = _a.className, inputProps = __rest(_a, ["alignCalendar", "initialDate", "value", "onDateChange", "selectedDateFormat", "disabled", "error", "required", "label", "name", "placeholder", "className"]);
    var _f = useState(false), focused = _f[0], setFocus = _f[1];
    var buttonRef = useRef(null);
    var toggleFocus = function (e) {
        e.preventDefault();
        setFocus(!focused);
    };
    var dismissAndHandleSelectedDate = function (date) {
        if (handleSelectedDate) {
            handleSelectedDate(date);
        }
        setFocus(false);
    };
    var _g = (_c = (_b = buttonRef.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect()) !== null && _c !== void 0 ? _c : {}, x = _g.x, y = _g.y, height = _g.height;
    return (React.createElement(React.Fragment, null,
        React.createElement(Field, { label: label, errorMessage: error, name: name, required: required, disabled: disabled, className: className },
            React.createElement("button", { className: "bg-white p-2 border pl-4 pr-8 rounded flex flex-grow relative", onClick: toggleFocus, type: "button", ref: buttonRef },
                value ? (React.createElement("p", { className: "text-gray-800" }, value)) : (React.createElement("p", { className: "text-gray-400" }, placeholder !== null && placeholder !== void 0 ? placeholder : selectedDateFormat)),
                React.createElement("div", { className: "absolute right-0 inset-y-0 flex text-contentAreaBorder" },
                    React.createElement(FontAwesomeIcon, { icon: faCalendarAlt, className: "text-gray-500 mr-2 my-auto" }))),
            React.createElement(Input, __assign({}, inputProps, { name: name, type: "hidden", ref: forwardedRef, value: value !== null && value !== void 0 ? value : '' }))),
        focused ? (React.createElement(Overlay, null,
            React.createElement("div", { className: "fixed bg-white shadow hover:shadow-lg mt-1 transition-shadow ease-in-out duration-500 z-50s w-64 my-auto", style: {
                    top: (y !== null && y !== void 0 ? y : 0) + (height !== null && height !== void 0 ? height : 0),
                    left: x,
                } },
                React.createElement(MiniCal, { initialDate: initialDate, selectedDate: value, onDateSelected: dismissAndHandleSelectedDate, selectedDateFormat: selectedDateFormat })),
            React.createElement("button", { className: "bg-white opacity-25 fixed z-40 h-screen w-screen top-0 left-0", onClick: toggleFocus }))) : null));
});
