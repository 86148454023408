var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { transparentize } from 'polished';
import * as React from 'react';
import styled from '../styled-components';
import { Badge } from './Badge';
var Section = styled.section(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0.5rem 0;\n  display: flex;\n  flex-direction: column;\n"], ["\n  padding: 0.5rem 0;\n  display: flex;\n  flex-direction: column;\n"])));
var Heading = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: ", ";\n  text-transform: uppercase;\n  font-size: 0.675rem;\n  letter-spacing: 0.1em;\n  font-weight: 400;\n"], ["\n  font-family: ", ";\n  text-transform: uppercase;\n  font-size: 0.675rem;\n  letter-spacing: 0.1em;\n  font-weight: 400;\n"])), function (p) { return p.theme.fontFamilyBody; });
var Navigation = styled.nav(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 0;\n  display: flex;\n  flex-shrink: 0;\n  flex-direction: column;\n"], ["\n  padding: 0;\n  display: flex;\n  flex-shrink: 0;\n  flex-direction: column;\n"])));
var NavigationGroup = styled.ul(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  padding: 0;\n  margin: 0;\n  list-style: none;\n  flex-direction: column;\n"], ["\n  display: flex;\n  padding: 0;\n  margin: 0;\n  list-style: none;\n  flex-direction: column;\n"])));
var NavigationOption = styled.li(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  cursor: ", ";\n  list-style: none;\n  padding: 0;\n  display: flex;\n  flex-direction: row;\n  font-size: 1.25rem;\n  color: ", ";\n"], ["\n  cursor: ", ";\n  list-style: none;\n  padding: 0;\n  display: flex;\n  flex-direction: row;\n  font-size: 1.25rem;\n  color: ", ";\n"])), function (p) { return (p.disabled ? 'not-allowed' : 'pointer'); }, function (p) { return p.theme.colorPrimary; });
var OptionButton = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  opacity: ", ";\n  pointer-events: ", ";\n  border: 0;\n  padding: 0.5rem;\n  background: ", ";\n  display: flex;\n  flex-direction: row;\n  flex-grow: 1;\n  transition: background 0.1s ease-out;\n\n  &:active:hover {\n    background: ", ";\n    color: ", ";\n  }\n\n  &:hover {\n    background: ", ";\n  }\n"], ["\n  opacity: ", ";\n  pointer-events: ", ";\n  border: 0;\n  padding: 0.5rem;\n  background: ", ";\n  display: flex;\n  flex-direction: row;\n  flex-grow: 1;\n  transition: background 0.1s ease-out;\n\n  &:active:hover {\n    background: ", ";\n    color: ", ";\n  }\n\n  &:hover {\n    background: ", ";\n  }\n"])), function (p) { return (p.disabled ? 0.5 : 1); }, function (p) { return (p.disabled ? 'none' : 'auto'); }, function (p) { return transparentize(1)(p.theme.colorPrimary); }, function (p) { return transparentize(0.7)(p.theme.colorPrimary); }, function (p) { return p.theme.colorBodyText; }, function (p) { return transparentize(0.9)(p.theme.colorPrimary); });
var OptionLabel = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 0.875rem;\n  line-height: 1.1;\n  margin: auto 10px;\n  display: block;\n  white-space: nowrap;\n"], ["\n  font-size: 0.875rem;\n  line-height: 1.1;\n  margin: auto 10px;\n  display: block;\n  white-space: nowrap;\n"])));
var OptionIcon = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  width: 1.75rem;\n  margin-right: 0.5rem;\n\n  & > * {\n    margin: auto;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  width: 1.75rem;\n  margin-right: 0.5rem;\n\n  & > * {\n    margin: auto;\n  }\n"])));
export var Heirarchy = function (_a) {
    var contents = _a.contents;
    return (React.createElement(Navigation, null, contents.map(function (content, i) { return (React.createElement(Section, { key: "section" + i + "-" + content.name },
        content.name ? React.createElement(Heading, null, content.name) : React.createElement("span", null),
        React.createElement(NavigationGroup, null, content.options.map(function (option, ii) { return (React.createElement(NavigationOption, { key: "section" + i + "-" + content.name + ".option" + ii + "-" + option.name, disabled: option.disabled },
            React.createElement(OptionButton, { onClick: option.onClick, disabled: option.disabled },
                React.createElement(OptionIcon, null, option.icon ? (React.createElement(FontAwesomeIcon, { icon: option.icon })) : (React.createElement("span", null))),
                React.createElement(OptionLabel, null, option.name),
                option.notificationCount && option.notificationCount > 0 ? (React.createElement(Badge, { amount: option.notificationCount })) : (React.createElement("span", null))))); })))); })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
