var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect, getIn } from 'formik';
import { transparentize } from 'polished';
import * as React from 'react';
import styled from '../styled-components';
var Error = styled.p(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  border: 1px solid ", ";\n  border-radius: ", ";\n  color: ", ";\n  display: flex;\n  flex-direction: row;\n  margin: 1rem auto;\n  padding: 0.5rem 0.875rem;\n\n  & > * {\n    margin: auto;\n  }\n"], ["\n  background: ", ";\n  border: 1px solid ", ";\n  border-radius: ", ";\n  color: ", ";\n  display: flex;\n  flex-direction: row;\n  margin: 1rem auto;\n  padding: 0.5rem 0.875rem;\n\n  & > * {\n    margin: auto;\n  }\n"])), function (p) { return transparentize(0.9)(p.theme.colorErrorText); }, function (p) { return transparentize(0.7)(p.theme.colorErrorText); }, function (p) { return p.theme.borderRadius; }, function (p) { return p.theme.colorErrorText; });
var Icon = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  opacity: 0.75rem;\n  padding-right: 1rem;\n  display: block;\n"], ["\n  opacity: 0.75rem;\n  padding-right: 1rem;\n  display: block;\n"])));
var ErrorComponent = function (props) {
    var error = getIn((props.formik || {}).errors, props.name);
    return error ? (React.createElement(Error, null,
        React.createElement(Icon, null,
            React.createElement(FontAwesomeIcon, { icon: faExclamationTriangle, size: '2x' })),
        ' ',
        React.createElement("span", null, error))) : null;
};
export var GeneralError = connect(ErrorComponent);
var templateObject_1, templateObject_2;
