var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { transparentize } from 'polished';
import * as React from 'react';
import styled from '../styled-components';
export var ErrorView = styled.article(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  padding: 18px;\n  width: auto;\n  flex-grow: 1;\n\n  & + & {\n    margin-top: 18px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  padding: 18px;\n  width: auto;\n  flex-grow: 1;\n\n  & + & {\n    margin-top: 18px;\n  }\n"])));
export var ErrorList = styled.ul(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 0;\n  margin: 1rem 0;\n  list-style: none;\n"], ["\n  padding: 0;\n  margin: 1rem 0;\n  list-style: none;\n"])));
export var ErrorItem = styled.li(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-bottom: 1px solid ", ";\n  color: ", ";\n  font-size: 0.875rem;\n  padding: 0.5rem 0;\n"], ["\n  border-bottom: 1px solid ", ";\n  color: ", ";\n  font-size: 0.875rem;\n  padding: 0.5rem 0;\n"])), function (p) { return p.theme.colorContentAreaBorder; }, function (p) { return p.theme.colorPrimary; });
export var ErrorCode = styled.pre(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 0.875rem;\n  border-radius: ", ";\n  background: ", ";\n  overflow-x: auto;\n  padding: 1rem;\n  white-space: pre-wrap;\n  word-wrap: break-word;\n"], ["\n  font-size: 0.875rem;\n  border-radius: ", ";\n  background: ", ";\n  overflow-x: auto;\n  padding: 1rem;\n  white-space: pre-wrap;\n  word-wrap: break-word;\n"])), function (p) { return p.theme.borderRadius; }, function (p) { return transparentize(0.85)(p.theme.colorPrimary); });
export var LoadingError = function (_a) {
    var error = _a.error;
    return (React.createElement(ErrorView, null,
        React.createElement("h2", null, "An error prevented this page from rendering:"),
        React.createElement(ErrorList, null,
            React.createElement(ErrorItem, null,
                React.createElement("strong", null, "Name:"),
                " ",
                error.name),
            React.createElement(ErrorItem, null,
                React.createElement("strong", null, "Message:"),
                " ",
                error.message),
            React.createElement(ErrorItem, null,
                React.createElement("strong", null, "Info:"),
                " ",
                error.extraInfo)),
        React.createElement("h3", null, "Network Error:"),
        React.createElement(ErrorCode, null, JSON.stringify(error.networkError)),
        React.createElement("h3", null, "GraphQL Error:"),
        React.createElement(ErrorCode, null, JSON.stringify(error.graphQLErrors)),
        React.createElement("h3", null, "Complete Stack Trace:"),
        React.createElement(ErrorCode, null, JSON.stringify(error))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
