var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Rotate } from '../Animations';
import styled from '../styled-components';
var IndicatorContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-grow: 0;\n  flex-shrink: 1;\n"], ["\n  display: flex;\n  flex-grow: 0;\n  flex-shrink: 1;\n"])));
export var Spinner = function (_a) {
    var size = _a.size;
    return (React.createElement(IndicatorContainer, null,
        React.createElement(Rotate, null,
            React.createElement(FontAwesomeIcon, { icon: faSpinnerThird, size: size }))));
};
var templateObject_1;
