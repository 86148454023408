import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
export var useOverlayRoot = function () { return document.getElementById("overlay-root"); };
export var Overlay = function (_a) {
    var children = _a.children;
    var overlayRoot = useOverlayRoot();
    var el = useRef(document.createElement("div"));
    useEffect(function () {
        overlayRoot === null || overlayRoot === void 0 ? void 0 : overlayRoot.appendChild(el.current);
        return function () {
            overlayRoot === null || overlayRoot === void 0 ? void 0 : overlayRoot.removeChild(el.current);
        };
    });
    return createPortal(children, el.current);
};
