import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/pro-regular-svg-icons';
export var Clipboard = function (_a) {
    var value = _a.value;
    var urlInput = useRef(null);
    var selectText = function (el) {
        var _a;
        el === null || el === void 0 ? void 0 : el.select();
        el === null || el === void 0 ? void 0 : el.setSelectionRange(0, (_a = el === null || el === void 0 ? void 0 : el.value.length) !== null && _a !== void 0 ? _a : 99999);
    };
    var handleFocus = function (e) {
        return selectText(e.currentTarget);
    };
    var handleChange = function (e) {
        return selectText(e.currentTarget);
    };
    var handleMouse = function (e) {
        e.preventDefault();
        selectText(e.currentTarget);
    };
    var handleCopyClick = function (e) {
        e.preventDefault();
        selectText(urlInput.current);
        document.execCommand('copy');
    };
    return (React.createElement("p", { className: "mt-2 flex" },
        React.createElement("input", { className: "flex flex-grow border rounded-l p-2 text-black text-sm border-r-0", ref: urlInput, value: value, onFocus: handleFocus, onChange: handleChange, onMouseUp: handleMouse }),
        React.createElement("button", { onClick: handleCopyClick, className: "flex bg-button hover:opacity-75 text-white text-lg py-2 px-4 rounded-r" },
            React.createElement(FontAwesomeIcon, { icon: faClipboard, className: "m-auto" }))));
};
