var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import styled from '../styled-components';
var BadgeContainer = styled.h4(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-content: auto;\n  border-radius: 0.6rem;\n  display: flex;\n  height: 1.2rem;\n  min-width: 1.2rem;\n  font-size: 0.6rem;\n  color: ", ";\n  background: ", ";\n  margin: auto 4px;\n  padding: 0 0.375rem;\n"], ["\n  align-content: auto;\n  border-radius: 0.6rem;\n  display: flex;\n  height: 1.2rem;\n  min-width: 1.2rem;\n  font-size: 0.6rem;\n  color: ", ";\n  background: ", ";\n  margin: auto 4px;\n  padding: 0 0.375rem;\n"])), function (p) { return p.theme.colorBodyTextInverted; }, function (p) { return p.theme.colorErrorText; });
var BadgeNumber = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: block;\n  margin: auto;\n"], ["\n  display: block;\n  margin: auto;\n"])));
export var Badge = function (_a) {
    var amount = _a.amount;
    return (React.createElement(BadgeContainer, null,
        React.createElement(BadgeNumber, null, amount)));
};
var templateObject_1, templateObject_2;
