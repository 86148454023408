var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import styled from '../styled-components';
var IconView = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  align-content: center;\n  margin: auto;\n  display: flex;\n  flex-direction: row;\n  flex-grow: 0;\n  height: 100%;\n  width: auto;\n\n  & > * {\n    display: block;\n    margin: auto;\n  }\n"], ["\n  color: ", ";\n  align-content: center;\n  margin: auto;\n  display: flex;\n  flex-direction: row;\n  flex-grow: 0;\n  height: 100%;\n  width: auto;\n\n  & > * {\n    display: block;\n    margin: auto;\n  }\n"])), function (p) { return p.theme.colorBodyText; });
export var Icon = function (_a) {
    var icon = _a.icon;
    return (React.createElement(IconView, null,
        React.createElement(FontAwesomeIcon, { icon: icon })));
};
var templateObject_1;
