var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import clsx from 'clsx';
import * as React from 'react';
export var ButtonGroup = function (_a) {
    var children = _a.children;
    return (React.createElement("ul", { className: "flex flex-grow" }, React.Children.map(children, function (c) { return (React.createElement("li", { className: "ml-2 first:ml-0 flex" },
        c,
        " ")); })));
};
var BUTTON_STYLES = 'rounded font-bold font-body text-white w-full text-center flex flex-grow align-center justify-center p-2';
export var Button = function (_a) {
    var children = _a.children, destructive = _a.destructive, disabled = _a.disabled, props = __rest(_a, ["children", "destructive", "disabled"]);
    return (React.createElement("button", __assign({}, props, { disabled: disabled, className: clsx(destructive ? 'bg-destructive' : 'bg-button', disabled && 'opacity-50 disabled', BUTTON_STYLES) }), React.Children.map(children, function (child) { return (React.createElement("span", { className: "block m-auto" }, child)); })));
};
