var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { media } from '../media';
import styled from '../styled-components';
var GridItem = styled.li(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background: ", ";\n  border: ", "px solid ", ";\n  border-radius: ", ";\n  cursor: pointer;\n  display: flex;\n  flex-grow: 1;\n  flex-direction: row;\n  padding: 12px;\n  margin: 0 6px 6px 0;\n  max-width: 100%;\n  transition: transform 0.2s ease-out, box-shadow 0.3s ease-out;\n  position: relative;\n\n  &:last-child {\n    margin-right: auto;\n\n  ", "\n  ", "\n  }\n\n  &:hover {\n    transform: translate3d(0,-2px, 0);\n    box-shadow: 0 3px 2px ", ";\n  }\n\n  ", "\n  ", "\n  ", "\n"], ["\n  background: ", ";\n  border: ", "px solid ", ";\n  border-radius: ", ";\n  cursor: pointer;\n  display: flex;\n  flex-grow: 1;\n  flex-direction: row;\n  padding: 12px;\n  margin: 0 6px 6px 0;\n  max-width: 100%;\n  transition: transform 0.2s ease-out, box-shadow 0.3s ease-out;\n  position: relative;\n\n  &:last-child {\n    margin-right: auto;\n\n  ", "\n  ", "\n  }\n\n  &:hover {\n    transform: translate3d(0,-2px, 0);\n    box-shadow: 0 3px 2px ", ";\n  }\n\n  ", "\n  ", "\n  ", "\n"])), function (p) { return p.theme.colorContentAreaBackground; }, function (p) { return (p.selected ? 2 : 1); }, function (p) {
    return p.selected
        ? p.theme.colorContentAreaBorderSelected
        : p.theme.colorContentAreaBorder;
}, function (p) { return p.theme.borderRadius; }, media.minSm(templateObject_1 || (templateObject_1 = __makeTemplateObject(["max-width: 49.333333%;"], ["max-width: 49.333333%;"]))), media.minLg(templateObject_2 || (templateObject_2 = __makeTemplateObject(["max-width: 32.333333%;"], ["max-width: 32.333333%;"]))), function (p) { return p.theme.colorContentAreaBorder; }, media.minXs(templateObject_3 || (templateObject_3 = __makeTemplateObject(["width: 100%;"], ["width: 100%;"]))), media.minSm(templateObject_4 || (templateObject_4 = __makeTemplateObject(["max-width: 49.333333%; flex-basis: 49.333333%;"], ["max-width: 49.333333%; flex-basis: 49.333333%;"]))), media.minLg(templateObject_5 || (templateObject_5 = __makeTemplateObject(["max-width: 32.333333%; flex-basis: 32.333333%;"], ["max-width: 32.333333%; flex-basis: 32.333333%;"]))));
export default GridItem;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
