var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { media } from '../media';
import styled from '../styled-components';
var COLLUMN_COUNT = 16;
var calculateSpan = function (span) {
    return (100.0 / COLLUMN_COUNT) * Math.min(span, COLLUMN_COUNT);
};
export var Col = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  padding: 0 0.5rem;\n\n  ", "\n  ", "\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  padding: 0 0.5rem;\n\n  ", "\n  ", "\n"])), media.minSm(templateObject_1 || (templateObject_1 = __makeTemplateObject(["max-width: 100%; margin-left: 0; margin-right: 0; width: 100%; flex-basis: 100%;"], ["max-width: 100%; margin-left: 0; margin-right: 0; width: 100%; flex-basis: 100%;"]))), function (p) { return media.minMd(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    flex-basis: ", "%; \n    width: ", "%; \n    max-width: ", "%; \n    margin-left: ", "%; \n    margin-right: ", "%;\n  "], ["\n    flex-basis: ", "%; \n    width: ", "%; \n    max-width: ", "%; \n    margin-left: ", "%; \n    margin-right: ", "%;\n  "])), calculateSpan(p.span), calculateSpan(p.span), calculateSpan(p.span), calculateSpan(p.push || 0), calculateSpan(p.pull || 0)); });
var templateObject_1, templateObject_2, templateObject_3;
