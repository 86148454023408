var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { transparentize } from 'polished';
import * as React from 'react';
import styled from '../styled-components';
export var SearchInput = styled.input(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  display: flex;\n  border: 2px solid ", ";\n  border-radius: 1rem;\n  font-size: 16px;\n  padding: 18px 18px 18px 2.25rem;\n  flex-grow: 1;\n  width: auto;\n  max-width: 18rem;\n  margin: 0;\n  outline: transparent;\n  opacity: 0.85;\n  transition: all 0.25s ease-in-out;\n  width: 100%;\n\n  &:focus {\n    background: ", ";\n    opacity: 1;\n    max-width: 100%;\n  }\n"], ["\n  background: ", ";\n  display: flex;\n  border: 2px solid ", ";\n  border-radius: 1rem;\n  font-size: 16px;\n  padding: 18px 18px 18px 2.25rem;\n  flex-grow: 1;\n  width: auto;\n  max-width: 18rem;\n  margin: 0;\n  outline: transparent;\n  opacity: 0.85;\n  transition: all 0.25s ease-in-out;\n  width: 100%;\n\n  &:focus {\n    background: ", ";\n    opacity: 1;\n    max-width: 100%;\n  }\n"])), function (p) { return transparentize(0.2)(p.theme.colorBodyTextInverted); }, function (p) { return p.theme.colorPrimary; }, function (p) { return transparentize(0)(p.theme.colorBodyTextInverted); });
export var SearchView = styled.p(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 0;\n  display: flex;\n  flex-grow: 1;\n  position: relative;\n"], ["\n  margin: 0;\n  display: flex;\n  flex-grow: 1;\n  position: relative;\n"])));
export var SearchIcon = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  position: absolute;\n  left: 0.5rem;\n  top: 0;\n  padding: 0.5rem;\n  display: block;\n  margin: 0;\n  z-index: 1;\n  pointer-events: none;\n"], ["\n  color: ", ";\n  position: absolute;\n  left: 0.5rem;\n  top: 0;\n  padding: 0.5rem;\n  display: block;\n  margin: 0;\n  z-index: 1;\n  pointer-events: none;\n"])), function (p) { return p.theme.colorPrimary; });
export var Search = function (_a) {
    var placeholder = _a.placeholder, onChange = _a.onChange, value = _a.value;
    return (React.createElement(SearchView, null,
        React.createElement(SearchIcon, null,
            React.createElement(FontAwesomeIcon, { icon: faSearch })),
        React.createElement(SearchInput, { type: "search", onChange: onChange, placeholder: placeholder, value: value })));
};
var templateObject_1, templateObject_2, templateObject_3;
