var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ReactSelect from 'react-select';
export var MultiSelect = React.forwardRef(function (_a, ref) {
    var id = _a.id, name = _a.name, label = _a.label, placeholder = _a.placeholder, _b = _a.options, options = _b === void 0 ? [] : _b, _c = _a.closeMenuOnSelect, closeMenuOnSelect = _c === void 0 ? false : _c, handleOnInputChange = _a.onChange, handleSelect = _a.onSelect, value = _a.value;
    var defaultValue = options.filter(function (option) { var _a; return ((_a = value === null || value === void 0 ? void 0 : value.indexOf(option.id)) !== null && _a !== void 0 ? _a : -1) > -1; });
    var handleChange = function (selection) {
        handleSelect === null || handleSelect === void 0 ? void 0 : handleSelect(selection.map(function (s) { return s.id; }).join(' '));
    };
    return (React.createElement("div", { className: "flex flex-col w-full" },
        React.createElement("label", { htmlFor: id }, label),
        React.createElement(ReactSelect, { isMulti: true, isClearable: true, id: id, name: name, value: defaultValue, options: options, formatOptionLabel: function (option) { return (React.createElement(React.Fragment, null,
                option.icon ? (React.createElement(FontAwesomeIcon, { icon: option.icon, className: "mr-2" })) : null,
                React.createElement("span", null, option.label))); }, getOptionLabel: function (option) { return option.label; }, getOptionValue: function (option) { return option.id; }, placeholder: placeholder, closeMenuOnSelect: closeMenuOnSelect, className: "pro__client__multi__select_container w-full", classNamePrefix: "pro__client__multi__select", onInputChange: handleOnInputChange, onChange: handleChange, styles: { menuPortal: function (base) { return (__assign(__assign({}, base), { zIndex: 9999 })); } }, menuPortalTarget: document.body }),
        React.createElement("input", { type: "hidden", ref: ref, name: name, id: id })));
});
