var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { concatStyles } from 'utils';
var INPUT_STYLE = 'font-body border rounded p-2 flex flex-grow max-w-full focus:outline-none focus:border-actionable';
var getErrorStyles = function (error) {
    return error ? 'border-error text-error' : '';
};
var getDisabledStyles = function (disabled) {
    return disabled ? 'opacity-50 cursor-not-allowed' : '';
};
export var Input = React.forwardRef(function (_a, forwardedRef) {
    var autoComplete = _a.autoComplete, name = _a.name, error = _a.error, _b = _a.type, type = _b === void 0 ? 'text' : _b, disabled = _a.disabled, className = _a.className, placeholder = _a.placeholder, style = _a.style, min = _a.min, onBlur = _a.onBlur, onChange = _a.onChange, onFocus = _a.onFocus, onKeyUp = _a.onKeyUp, onKeyDown = _a.onKeyDown, value = _a.value;
    var controlledValues = value ? { value: value } : {};
    return (React.createElement("input", __assign({ name: name, ref: forwardedRef, className: concatStyles([
            INPUT_STYLE,
            getErrorStyles(error),
            getDisabledStyles(disabled),
            className,
        ]), type: type, min: min, disabled: disabled, placeholder: placeholder, style: style, onBlur: onBlur, onChange: onChange, onFocus: onFocus, onKeyUp: onKeyUp, onKeyDown: onKeyDown, autoComplete: autoComplete ? 'on' : 'off' }, controlledValues)));
});
Input.displayName = 'Form.Input';
