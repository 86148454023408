import * as React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
var LIST_STYLES = 'mb-4 px-0 py-2 flex flex-row uppercase text-sm font-bold text-primary font-header';
var LIST_ITEM_STYLES = 'flex m-0';
var LINK_STYLES = 'my-auto pl-2';
var SEPARATOR_STYLES = 'opacity-25 pl-2 display-none:last-child text-center';
var Separator = function () { return (React.createElement("span", { "aria-hidden": "true", className: SEPARATOR_STYLES }, ">")); };
export var BreadCrumb = function (_a) {
    var _b = _a.trail, trail = _b === void 0 ? [] : _b;
    return (React.createElement("ul", { className: LIST_STYLES },
        React.createElement("li", { className: LIST_ITEM_STYLES },
            React.createElement("a", { href: "https://rpmed.com", className: 'my-auto' }, "Home"),
            React.createElement(Separator, null)),
        trail.map(function (item, i) {
            var _a;
            var last = i === trail.length - 1;
            return (React.createElement("li", { className: clsx(LIST_ITEM_STYLES, last && 'opacity-50'), key: "" + item.label + i },
                item.url ? (React.createElement("a", { href: item.url, className: LINK_STYLES }, item.label)) : (React.createElement(Link, { to: (_a = item.to) !== null && _a !== void 0 ? _a : '', className: LINK_STYLES }, item.label)),
                ' ',
                !last ? React.createElement(Separator, null) : null));
        })));
};
