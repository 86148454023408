var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { darken } from 'polished';
import styled from '../styled-components';
export var Size;
(function (Size) {
    Size[Size["Small"] = 0] = "Small";
    Size[Size["Medium"] = 1] = "Medium";
    Size[Size["Large"] = 2] = "Large";
})(Size || (Size = {}));
var getFontSize = function (size) {
    switch (size) {
        case Size.Large:
            return '1rem';
        case Size.Medium:
            return '0.75rem';
        default:
            return '0.5rem';
    }
};
var getPadding = function (size) {
    switch (size) {
        case Size.Large:
            return '0.35rem 0.75rem';
        default:
            return '0.25rem 0.5rem';
    }
};
export var List = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-direction: row;\n  display: flex;\n  flex-wrap: wrap;\n  margin: auto 0;\n"], ["\n  flex-direction: row;\n  display: flex;\n  flex-wrap: wrap;\n  margin: auto 0;\n"])));
export var Action = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: transparent;\n  color: ", ";\n  cursor: pointer;\n  border: none;\n  padding: 0 0 0 0.5rem;\n  margin: auto;\n"], ["\n  background: transparent;\n  color: ", ";\n  cursor: pointer;\n  border: none;\n  padding: 0 0 0 0.5rem;\n  margin: auto;\n"])), function (p) { return p.theme.colorBodyTextInverted; });
export var Primary = styled.h5(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  font-size: ", ";\n  font-weight: 300;\n  line-height: 1.15em;\n  font-family: ", ";\n  text-transform: uppercase;\n  color: ", ";\n  border: 0;\n  border-radius: 1rem;\n  background: ", ";\n  padding: ", ";\n  position: relative;\n  text-align: center;\n  margin: 0.2rem 0.1rem;\n  flex-grow: 0;\n"], ["\n  display: flex;\n  font-size: ", ";\n  font-weight: 300;\n  line-height: 1.15em;\n  font-family: ", ";\n  text-transform: uppercase;\n  color: ", ";\n  border: 0;\n  border-radius: 1rem;\n  background: ", ";\n  padding: ", ";\n  position: relative;\n  text-align: center;\n  margin: 0.2rem 0.1rem;\n  flex-grow: 0;\n"])), function (p) { return getFontSize(p.size || Size.Small); }, function (p) { return p.theme.fontFamilyBody; }, function (p) { return p.theme.colorBodyTextInverted; }, function (p) { return p.theme.colorPrimary; }, function (p) { return getPadding(p.size || Size.Small); });
export var Secondary = styled(Primary)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: transparent;\n  color: ", ";\n  border: 1px solid ", ";\n"], ["\n  background: transparent;\n  color: ", ";\n  border: 1px solid ", ";\n"])), function (p) { return p.theme.colorPrimary; }, function (p) { return p.theme.colorPrimary; });
export var Error = styled(Primary)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background: ", ";\n  color: ", ";\n"], ["\n  background: ", ";\n  color: ", ";\n"])), function (p) { return p.theme.colorButtonDestructive; }, function (p) { return p.theme.colorBodyTextInverted; });
export var ErrorInverted = styled(Primary)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background: transparent;\n  color: ", ";\n  border: 1px solid ", ";\n"], ["\n  background: transparent;\n  color: ", ";\n  border: 1px solid ", ";\n"])), function (p) { return p.theme.colorButtonDestructive; }, function (p) { return p.theme.colorButtonDestructive; });
export var Actionable = styled(Primary)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background: ", ";\n  color: ", ";\n  cursor: pointer;\n  border: 1px solid ", ";\n"], ["\n  background: ", ";\n  color: ", ";\n  cursor: pointer;\n  border: 1px solid ", ";\n"])), function (p) { return p.theme.colorButtonPrimary; }, function (p) { return p.theme.colorBodyTextInverted; }, function (p) { return p.theme.colorButtonPrimary; });
export var ActionableSecondary = styled(Secondary)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  background: transparent;\n  color: ", ";\n  cursor: pointer;\n  border: 1px solid ", ";\n"], ["\n  background: transparent;\n  color: ", ";\n  cursor: pointer;\n  border: 1px solid ", ";\n"])), function (p) { return darken(0.1)(p.theme.colorButtonPrimary); }, function (p) { return darken(0.1)(p.theme.colorButtonPrimary); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
