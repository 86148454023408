var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { faChevronCircleRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { transparentize } from 'polished';
import React from 'react';
import { Box, Text } from 'rebass';
import * as Card from '../Card';
import LayoutContent from '../Content';
import { InternalError } from '../Errors';
import { Layout } from '../Layout';
import { media } from '../media';
import styled from '../styled-components';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  min-height: 100vh;\n  margin: 0;\n  position: relative;\n  flex-grow: 1;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: row;\n  min-height: 100vh;\n  margin: 0;\n  position: relative;\n  flex-grow: 1;\n  width: 100%;\n"])));
var Sticky = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: sticky;\n  top: 0;\n  left: 0;\n  width: 100%;\n"], ["\n  position: sticky;\n  top: 0;\n  left: 0;\n  width: 100%;\n"])));
var Content = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  flex-grow: 1;\n  flex-shrink: 1;\n  margin: 0;\n"], ["\n  display: flex;\n  flex-direction: row;\n  flex-grow: 1;\n  flex-shrink: 1;\n  margin: 0;\n"])));
var Header = styled.header(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  flex-grow: 0;\n  flex-shrink: 1;\n  margin: 0;\n  padding: 0.75rem 0;\n"], ["\n  display: flex;\n  flex-direction: row;\n  flex-grow: 0;\n  flex-shrink: 1;\n  margin: 0;\n  padding: 0.75rem 0;\n"])));
var Title = styled.h2(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-weight: 600;\n  font-size: 1.45rem;\n  margin: 0;\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-weight: 600;\n  font-size: 1.45rem;\n  margin: 0;\n"])), function (p) { return p.theme.colorBodyText; }, function (p) { return p.theme.fontFamilyBody; });
var Nav = styled.nav(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 14rem;\n  display: none;\n  flex-grow: 0;\n  flex-shrink: 0;\n  flex-direction: column;\n  padding: 0.2rem 1rem;\n  background: ", ";\n  position: relative;\n\n  ", "\n"], ["\n  width: 14rem;\n  display: none;\n  flex-grow: 0;\n  flex-shrink: 0;\n  flex-direction: column;\n  padding: 0.2rem 1rem;\n  background: ", ";\n  position: relative;\n\n  ", "\n"])), function (p) { return transparentize(0.9)(p.theme.colorPrimary); }, media.minMd(templateObject_6 || (templateObject_6 = __makeTemplateObject(["display: flex;"], ["display: flex;"]))));
var SecondaryNavList = styled.ul(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  list-style: none;\n  margin: 0;\n  padding: 0.5rem 0;\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n"], ["\n  list-style: none;\n  margin: 0;\n  padding: 0.5rem 0;\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n"])));
var Item = styled.li(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin: 0;\n  padding: 0;\n  display: flex;\n  flex-grow: 0;\n"], ["\n  margin: 0;\n  padding: 0;\n  display: flex;\n  flex-grow: 0;\n"])));
export var Bubble = styled(Box)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  border-radius: ", ";\n  background: ", ";\n  text-align: center;\n  flex-shrink: 0;\n  font-weight: ", ";\n"], ["\n  border-radius: ", ";\n  background: ", ";\n  text-align: center;\n  flex-shrink: 0;\n  font-weight: ", ";\n"])), function (p) { return p.theme.borderRadius; }, function (p) {
    return transparentize(p.disabled ? 0.9 : 0.75)(p.theme.colorPrimary);
}, function (p) { return (p.disabled ? '400' : '600'); });
var Button = styled.button(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  background: ", ";\n  border: none;\n  border-radius: 0.1rem;\n  color: ", ";\n  display: flex;\n  font-size: 0.875rem;\n  flex-grow: 1;\n  padding: 0.35rem 0.5rem;\n  margin: 0;\n  text-align: center;\n\n  &:hover {\n    background: ", ";\n  }\n"], ["\n  background: ", ";\n  border: none;\n  border-radius: 0.1rem;\n  color: ", ";\n  display: flex;\n  font-size: 0.875rem;\n  flex-grow: 1;\n  padding: 0.35rem 0.5rem;\n  margin: 0;\n  text-align: center;\n\n  &:hover {\n    background: ", ";\n  }\n"])), function (p) {
    return p.selected ? transparentize(0.9)(p.theme.colorPrimary) : 'transparent';
}, function (p) { return p.theme.colorBodyText; }, function (p) { return transparentize(0.9)(p.theme.colorPrimary); });
var IconView = styled.span(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  background: ", ";\n  border-radius: ", ";\n  color: ", ";\n  align-content: center;\n  margin: auto 0.75rem auto 0;\n  display: flex;\n  flex-direction: row;\n  flex-grow: 0;\n  width: auto;\n  padding: 0.6rem 0.5rem;\n\n  & > * {\n    display: block;\n    margin: auto;\n  }\n"], ["\n  background: ", ";\n  border-radius: ", ";\n  color: ", ";\n  align-content: center;\n  margin: auto 0.75rem auto 0;\n  display: flex;\n  flex-direction: row;\n  flex-grow: 0;\n  width: auto;\n  padding: 0.6rem 0.5rem;\n\n  & > * {\n    display: block;\n    margin: auto;\n  }\n"])), function (p) { return p.theme.colorButtonPrimary; }, function (p) { return p.theme.borderRadius; }, function (p) { return p.theme.colorBodyTextInverted; });
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { hasError: false, error: null, errorInfo: {} };
        return _this;
    }
    ErrorBoundary.getDerivedStateFromError = function (error) {
        // tslint:disable-next-line no-console
        console.log(error);
        return { hasError: true, error: error };
    };
    ErrorBoundary.prototype.componentDidCatch = function (error, errorInfo) {
        // TODO: Log error via service such as Sentry
    };
    ErrorBoundary.prototype.render = function () {
        var _a = this.state, hasError = _a.hasError, error = _a.error, errorInfo = _a.errorInfo;
        return hasError ? (React.createElement(Layout, null,
            React.createElement(LayoutContent, null,
                React.createElement(Card.Flat, null,
                    React.createElement(InternalError, { error: error, extraInfo: errorInfo }))))) : (this.props.children);
    };
    return ErrorBoundary;
}(React.Component));
var ButtonIconView = styled(IconView)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  background: transparent;\n  color: ", ";\n  padding: 0;\n  min-width: 1.5rem;\n"], ["\n  background: transparent;\n  color: ", ";\n  padding: 0;\n  min-width: 1.5rem;\n"])), function (p) { return p.theme.colorBodyText; });
export var View = function (_a) {
    var children = _a.children, data = _a.data, hidden = _a.hidden, icon = _a.icon, title = _a.title;
    return (React.createElement(Container, null,
        hidden ? null : (React.createElement(Nav, { hidden: hidden },
            React.createElement(Sticky, null,
                title ? (React.createElement(Header, null,
                    icon ? (React.createElement(IconView, null,
                        React.createElement(FontAwesomeIcon, { icon: icon }))) : null,
                    React.createElement(Title, null, title))) : null,
                React.createElement(SecondaryNavList, null, data.map(function (_a, index) {
                    var label = _a.label, onClick = _a.onClick, selected = _a.selected, accessory = _a.accessory, p = __rest(_a, ["label", "onClick", "selected", "accessory"]);
                    return (React.createElement(Item, { key: label + "-" + index },
                        React.createElement(Button, { onClick: onClick, disabled: !onClick, selected: selected },
                            React.createElement(ButtonIconView, null,
                                React.createElement(FontAwesomeIcon, { icon: p.icon || faChevronCircleRight })),
                            React.createElement(Text, { textAlign: "left", width: 1 }, label),
                            accessory ? accessory : null)));
                }))))),
        React.createElement(Content, null,
            React.createElement(ErrorBoundary, null, children))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;
