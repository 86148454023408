var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { darken, lighten, transparentize } from 'polished';
import * as React from 'react';
import styled from '../styled-components';
import { Badge } from './Badge';
var useState = React.useState;
export var VisualMode;
(function (VisualMode) {
    VisualMode["Global"] = "global";
    VisualMode["Drawer"] = "drawer";
})(VisualMode || (VisualMode = {}));
export var GlobalItemIcon = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-content: center;\n  margin: auto;\n  display: flex;\n  flex-direction: row;\n  flex-grow: 0;\n  height: 100%;\n  width: auto;\n  font-size: 1.25rem;\n\n  & > * {\n    display: block;\n    margin: auto;\n  }\n"], ["\n  align-content: center;\n  margin: auto;\n  display: flex;\n  flex-direction: row;\n  flex-grow: 0;\n  height: 100%;\n  width: auto;\n  font-size: 1.25rem;\n\n  & > * {\n    display: block;\n    margin: auto;\n  }\n"
    // eslint-disable-next-line no-unexpected-multiline
])));
// eslint-disable-next-line no-unexpected-multiline
export var GlobalItemView = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: transparent;\n  color: ", ";\n  cursor: ", ";\n  border-radius: 1.5rem;\n  display: flex;\n  border: none;\n  height: 2.5rem;\n  width: 2.5rem;\n  display: flex;\n  align-items: center;\n  padding: 0.5rem;\n  position: relative;\n  margin: 0.25rem auto;\n  transition: background 0.1s ease-out;\n  &:focus {\n    outline: 0;\n  }\n  &:hover {\n    background: ", ";\n  }\n"], ["\n  background: transparent;\n  color: ", ";\n  cursor: ", ";\n  border-radius: 1.5rem;\n  display: flex;\n  border: none;\n  height: 2.5rem;\n  width: 2.5rem;\n  display: flex;\n  align-items: center;\n  padding: 0.5rem;\n  position: relative;\n  margin: 0.25rem auto;\n  transition: background 0.1s ease-out;\n  &:focus {\n    outline: 0;\n  }\n  &:hover {\n    background: ", ";\n  }\n"])), function (p) {
    return p.visualMode === VisualMode.Drawer
        ? p.theme.colorPrimary
        : p.theme.colorSecondary;
}, function (p) { return (p.interactive ? 'pointer' : 'default'); }, function (p) {
    return p.interactive
        ? p.visualMode === VisualMode.Drawer
            ? darken(0.05)(p.theme.colorDrawerBackground)
            : lighten(0.1)(p.theme.colorPrimary)
        : 'transparent';
});
var ToolTip = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: ", ";\n  border-radius: ", ";\n  font-size: 0.625rem;\n  padding: 0.2rem 0.5rem;\n  white-space: nowrap;\n  font-family: ", ";\n  color: ", ";\n  font-weight: 400;\n  position: absolute;\n  pointer-events: none;\n  left: 100%;\n  top: 50%;\n  margin-top: -0.55rem;\n  text-transform: lowercase;\n  letter-spacing: 0.05em;\n  opacity: ", ";\n  transform: translate(", "px, 0);\n  transition: all 0.4s ease-in-out;\n  z-index: 2;\n\n  &::before {\n    content: '';\n    position: absolute;\n    top: 50%;\n    margin-top: -5px;\n    height: 0;\n    right: 100%;\n    border-top: 5px solid transparent;\n    border-bottom: 5px solid transparent;\n    border-right: 5px solid\n      ", ";\n    width: 0;\n    z-index: 2;\n  }\n"], ["\n  background: ", ";\n  border-radius: ", ";\n  font-size: 0.625rem;\n  padding: 0.2rem 0.5rem;\n  white-space: nowrap;\n  font-family: ", ";\n  color: ", ";\n  font-weight: 400;\n  position: absolute;\n  pointer-events: none;\n  left: 100%;\n  top: 50%;\n  margin-top: -0.55rem;\n  text-transform: lowercase;\n  letter-spacing: 0.05em;\n  opacity: ", ";\n  transform: translate(", "px, 0);\n  transition: all 0.4s ease-in-out;\n  z-index: 2;\n\n  &::before {\n    content: '';\n    position: absolute;\n    top: 50%;\n    margin-top: -5px;\n    height: 0;\n    right: 100%;\n    border-top: 5px solid transparent;\n    border-bottom: 5px solid transparent;\n    border-right: 5px solid\n      ", ";\n    width: 0;\n    z-index: 2;\n  }\n"])), function (p) { return transparentize(0.2)(darken(0.5)(p.theme.colorPrimary)); }, function (p) { return p.theme.borderRadius; }, function (p) { return p.theme.fontFamilyBody; }, function (p) { return p.theme.colorBodyTextInverted; }, function (p) { return (p.active ? 1 : 0); }, function (p) { return (p.active ? 5 : 0); }, function (p) { return transparentize(0.2)(darken(0.5)(p.theme.colorPrimary)); });
var AccessoryView = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: block;\n  position: absolute;\n  top: 60%;\n  left: 70%;\n  z-index: 1;\n"], ["\n  display: block;\n  position: absolute;\n  top: 60%;\n  left: 70%;\n  z-index: 1;\n"])));
export var GlobalItem = function (_a) {
    var badgeAmount = _a.badgeAmount, icon = _a.icon, label = _a.label, onClick = _a.onClick, visualMode = _a.visualMode;
    var interactive = typeof onClick === 'function';
    var _b = useState(false), hovering = _b[0], setHovering = _b[1];
    var handleMouseOver = function () { return setHovering(true && interactive); };
    var handleMouseOut = function () { return setHovering(false); };
    return (React.createElement(GlobalItemView, { onMouseOver: handleMouseOver, onMouseOut: handleMouseOut, onClick: onClick, visualMode: visualMode, interactive: interactive },
        React.createElement(GlobalItemIcon, null,
            React.createElement(FontAwesomeIcon, { icon: icon })),
        label ? React.createElement(ToolTip, { active: hovering }, label) : React.createElement("span", null),
        badgeAmount ? (React.createElement(AccessoryView, null,
            React.createElement(Badge, { amount: badgeAmount }))) : (React.createElement("span", null))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
