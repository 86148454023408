import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { concatStyles } from 'utils';
import { Error } from './Error';
export var getErrorMessage = function (_a) {
    var _b;
    var name = _a.name, errorMessage = _a.errorMessage, errors = _a.errors;
    return errorMessage !== null && errorMessage !== void 0 ? errorMessage : (_b = (errors !== null && errors !== void 0 ? errors : {})[name]) === null || _b === void 0 ? void 0 : _b.message;
};
var CONTAINER_STYLES = 'flex flex-col';
var ICON_WRAPPER_STYLES = 'absolute inset-y-0 flex text-contentAreaBorder';
var ICON_STYLES = 'm-auto text-gray-500';
var WRAPPER_STYLES = 'relative flex flex-grow flex-wrap';
var REQUIRED_STYLES = 'text-accent text-xs mx-2 my-auto';
var getGrowStyle = function (grow) { return (grow ? 'flex-grow' : ''); };
var getDisabledStyle = function (disabled) { return (disabled ? 'opacity-75' : ''); };
var getIconPositionIf = function (searchField) {
    return searchField ? 'left-0 ml-3' : 'right-0 mr-3';
};
export var Field = function (_a) {
    var label = _a.label, name = _a.name, className = _a.className, labelClassName = _a.labelClassName, required = _a.required, icon = _a.icon, errorMessage = _a.errorMessage, grow = _a.grow, children = _a.children, disabled = _a.disabled, searchField = _a.searchField;
    return (React.createElement("div", { className: concatStyles([
            CONTAINER_STYLES,
            getGrowStyle(grow),
            getDisabledStyle(disabled),
            className,
        ]) },
        React.createElement("label", { htmlFor: name, className: concatStyles('flex', labelClassName) },
            label,
            ' ',
            required && (React.createElement(FontAwesomeIcon, { icon: faAsterisk, className: REQUIRED_STYLES }))),
        React.createElement("div", { className: WRAPPER_STYLES },
            children,
            icon ? (React.createElement("span", { className: concatStyles([
                    ICON_WRAPPER_STYLES,
                    getIconPositionIf(searchField),
                ]) },
                React.createElement(FontAwesomeIcon, { icon: icon, className: ICON_STYLES }))) : (React.createElement("span", null)),
            errorMessage ? React.createElement(Error, null, errorMessage) : null)));
};
Field.displayName = 'Form.Field';
