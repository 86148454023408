var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Logo } from '../Brand';
import { media } from '../media';
import styled from '../styled-components';
var Flex = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n\n  & > * {\n    display: inline-block;\n    margin: auto;\n  }\n\n  & > :last-child {\n    margin: auto auto auto 10px;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n\n  & > * {\n    display: inline-block;\n    margin: auto;\n  }\n\n  & > :last-child {\n    margin: auto auto auto 10px;\n  }\n"])));
var Spacer = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: block;\n  margin-left: auto;\n"], ["\n  display: block;\n  margin-left: auto;\n"])));
var Contents = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 12px 20px;\n  display: flex;\n  flex-direction: column;\n"], ["\n  padding: 12px 20px;\n  display: flex;\n  flex-direction: column;\n"])));
var CollapsableRow = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  ", "\n"], ["\n  display: flex;\n  ", "\n"])), media.minSm(templateObject_4 || (templateObject_4 = __makeTemplateObject([" flex-direction: row;"], [" flex-direction: row;"]))));
var HiddenOnMobile = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: none;\n  ", "\n"], ["\n  display: none;\n  ", "\n"])), media.minSm(templateObject_6 || (templateObject_6 = __makeTemplateObject(["display: block;"], ["display: block;"]))));
var Primary = function () { return (React.createElement("div", { className: "bg-primary text-white font-bold flex" },
    React.createElement("ul", { className: "flex flex-row flex-grow mx-auto p-2", style: {
            maxWidth: '1170px',
        } },
        React.createElement("li", { className: "flex my-auto mr-auto" },
            ' ',
            React.createElement("a", { href: "https://rpmed.com", target: "_blank", rel: "noopener noreferrer" },
                React.createElement(Logo, null))),
        React.createElement("li", { className: "ml-auto my-auto flex" },
            React.createElement("a", { href: "https://rpmed.com", target: "_blank", rel: "noopener noreferrer", className: "text-white flex" },
                React.createElement("span", { className: "hidden md:flex mr-2 my-auto text-sm font-light" }, "Go to Riverpoint"),
                React.createElement(FontAwesomeIcon, { icon: faExternalLinkAlt, className: "my-auto flex" })))))); };
export default Primary;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
