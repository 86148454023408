var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { concatStyles } from "utils";
var INPUT_STYLE = "font-body border rounded p-2 flex flex-grow max-w-full focus:outline-none focus:border-actionable";
var getErrorStyles = function (error) {
    return error ? "border-error text-error" : "";
};
var getDisabledStyles = function (disabled) {
    return disabled ? "opacity-50 cursor-not-allowed" : "";
};
export var TextArea = React.forwardRef(function (_a, forwardedRef) {
    var name = _a.name, error = _a.error, disabled = _a.disabled, className = _a.className, placeholder = _a.placeholder, value = _a.value, style = _a.style, onBlur = _a.onBlur, onChange = _a.onChange, onFocus = _a.onFocus, onKeyUp = _a.onKeyUp, onKeyDown = _a.onKeyDown;
    var controlledInputProps = value ? { value: value } : {};
    return (React.createElement("textarea", __assign({ name: name, ref: forwardedRef, className: concatStyles([
            INPUT_STYLE,
            getErrorStyles(error),
            getDisabledStyles(disabled),
            className,
        ]), disabled: disabled, placeholder: placeholder, style: style, onBlur: onBlur, onChange: onChange, onFocus: onFocus, onKeyUp: onKeyUp, onKeyDown: onKeyDown }, controlledInputProps)));
});
TextArea.displayName = "Form.TextArea";
