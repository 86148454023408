import React from 'react';
import ReactSelect from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export var Select = React.forwardRef(function (_a, ref) {
    var id = _a.id, name = _a.name, placeholder = _a.placeholder, _b = _a.options, options = _b === void 0 ? [] : _b, handleOnInputChange = _a.onChange, handleSelect = _a.onSelect, value = _a.value, disabled = _a.disabled, clearable = _a.clearable;
    var defaultValue = options.filter(function (s) { return s.id === value; });
    var handleChange = function (s) {
        var _a;
        handleSelect === null || handleSelect === void 0 ? void 0 : handleSelect((_a = s === null || s === void 0 ? void 0 : s.id) !== null && _a !== void 0 ? _a : null);
    };
    return (React.createElement("div", { className: "w-full flex-grow flex" },
        React.createElement(ReactSelect, { name: name, options: options, value: defaultValue, defaultValue: defaultValue, getOptionLabel: function (option) { return option.name; }, getOptionValue: function (option) { return option.id; }, formatOptionLabel: function (option) { return (React.createElement(React.Fragment, null,
                option.icon ? (React.createElement(FontAwesomeIcon, { icon: option.icon, className: "mr-2" })) : null,
                React.createElement("span", null, option.name))); }, placeholder: placeholder, className: "client__multi__select_container w-full", classNamePrefix: "client__multi__select", onInputChange: handleOnInputChange, isClearable: clearable, isDisabled: disabled, onChange: handleChange, closeMenuOnSelect: true }),
        React.createElement("input", { type: "hidden", ref: ref, name: name, id: id })));
});
