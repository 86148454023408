var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { media } from '../media';
import styled from '../styled-components';
export var One = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 2.4rem;\n  font-family: ", ";\n  font-weight: 400;\n  line-height: 1;\n  margin-bottom: 0.25em;\n\n  ", "\n"], ["\n  font-size: 2.4rem;\n  font-family: ", ";\n  font-weight: 400;\n  line-height: 1;\n  margin-bottom: 0.25em;\n\n  ", "\n"])), function (p) { return p.theme.fontFamilyHeader; }, media.minSm(templateObject_1 || (templateObject_1 = __makeTemplateObject(["font-size: 3rem;"], ["font-size: 3rem;"]))));
export var Two = styled.h2(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 2.2rem;\n  line-height: 1.25;\n  font-family: ", ";\n  font-weight: 400;\n  margin: 0 1em;\n\n  ", "\n"], ["\n  font-size: 2.2rem;\n  line-height: 1.25;\n  font-family: ", ";\n  font-weight: 400;\n  margin: 0 1em;\n\n  ", "\n"])), function (p) { return p.theme.fontFamilyHeader; }, media.minSm(templateObject_3 || (templateObject_3 = __makeTemplateObject(["font-size: 2.4rem;"], ["font-size: 2.4rem;"]))));
export var Three = styled.h3(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-family: ", ";\n  font-size: 2rem;\n  font-weight: 500;\n  line-height: 1.2;\n\n  ", "\n"], ["\n  font-family: ", ";\n  font-size: 2rem;\n  font-weight: 500;\n  line-height: 1.2;\n\n  ", "\n"])), function (p) { return p.theme.fontFamilyHeader; }, media.minSm(templateObject_5 || (templateObject_5 = __makeTemplateObject(["font-size: 2.2rem;"], ["font-size: 2.2rem;"]))));
export var Four = styled.h4(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-top: 3.2rem;\n  margin-bottom: 0.8rem;\n  font-size: 1.2rem;\n  font-weight: 500;\n  line-height: 2.4rem;\n  font-family: ", ";\n  text-transform: uppercase;\n  letter-spacing: 0.1rem;\n\n  ", "\n"], ["\n  margin-top: 3.2rem;\n  margin-bottom: 0.8rem;\n  font-size: 1.2rem;\n  font-weight: 500;\n  line-height: 2.4rem;\n  font-family: ", ";\n  text-transform: uppercase;\n  letter-spacing: 0.1rem;\n\n  ", "\n"])), function (p) { return p.theme.fontFamilyBody; }, media.minSm(templateObject_7 || (templateObject_7 = __makeTemplateObject(["font-size: 1.6rem;"], ["font-size: 1.6rem;"]))));
export var ToolBarOne = styled.h2(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin-top: 0;\n  margin-bottom: 0.25rem;\n  font-size: 1rem;\n  font-weight: 600;\n  line-height: 1em;\n  font-family: ", ";\n  text-transform: uppercase;\n  color: ", ";\n"], ["\n  margin-top: 0;\n  margin-bottom: 0.25rem;\n  font-size: 1rem;\n  font-weight: 600;\n  line-height: 1em;\n  font-family: ", ";\n  text-transform: uppercase;\n  color: ", ";\n"])), function (p) { return p.theme.fontFamilyBody; }, function (p) { return p.theme.colorPrimary; });
export var ToolBarTwo = styled.h3(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin-top: 0;\n  margin-bottom: 0;\n  font-size: 0.875rem;\n  font-weight: 400;\n  line-height: 1em;\n  font-family: ", ";\n  text-transform: uppercase;\n  color: ", ";\n"], ["\n  margin-top: 0;\n  margin-bottom: 0;\n  font-size: 0.875rem;\n  font-weight: 400;\n  line-height: 1em;\n  font-family: ", ";\n  text-transform: uppercase;\n  color: ", ";\n"])), function (p) { return p.theme.fontFamilyBody; }, function (p) { return p.theme.colorPrimary; });
export var Title = styled.h2(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  margin-top: 0;\n  margin-bottom: 0.25rem;\n  font-size: 1.2rem;\n  font-weight: 600;\n  line-height: 1.15em;\n  font-family: ", ";\n  text-transform: uppercase;\n  color: ", ";\n"], ["\n  margin-top: 0;\n  margin-bottom: 0.25rem;\n  font-size: 1.2rem;\n  font-weight: 600;\n  line-height: 1.15em;\n  font-family: ", ";\n  text-transform: uppercase;\n  color: ", ";\n"])), function (p) { return p.theme.fontFamilyBody; }, function (p) { return p.theme.colorPrimary; });
export var Section = styled.h3(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  margin-top: 0;\n  margin-bottom: 0.25rem;\n  font-size: 1rem;\n  font-weight: 400;\n  line-height: 1.15em;\n  font-family: ", ";\n  text-transform: uppercase;\n  color: ", ";\n"], ["\n  margin-top: 0;\n  margin-bottom: 0.25rem;\n  font-size: 1rem;\n  font-weight: 400;\n  line-height: 1.15em;\n  font-family: ", ";\n  text-transform: uppercase;\n  color: ", ";\n"])), function (p) { return p.theme.fontFamilyBody; }, function (p) { return p.theme.colorPrimary; });
export var Label = styled.h4(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  margin-top: 1rem;\n  margin-bottom: 0.25rem;\n  font-size: 0.75rem;\n  font-weight: 300;\n  line-height: 1.15em;\n  font-family: ", ";\n  text-transform: uppercase;\n  color: ", ";\n"], ["\n  margin-top: 1rem;\n  margin-bottom: 0.25rem;\n  font-size: 0.75rem;\n  font-weight: 300;\n  line-height: 1.15em;\n  font-family: ", ";\n  text-transform: uppercase;\n  color: ", ";\n"])), function (p) { return p.theme.fontFamilyBody; }, function (p) { return p.theme.colorPrimary; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;
