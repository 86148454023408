var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { faLongArrowLeft, faShieldAlt, } from '@fortawesome/pro-regular-svg-icons';
import { lighten, transparentize } from 'polished';
import * as React from 'react';
import styled from '../styled-components';
import { SIDEBAR_WIDTH } from './constants';
import { Divider } from './Divider';
import { GlobalItem, VisualMode } from './GlobalItem';
import { Sidebar } from './Sidebar';
export var DrawerWidth;
(function (DrawerWidth) {
    DrawerWidth[DrawerWidth["Small"] = 420] = "Small";
    DrawerWidth[DrawerWidth["Medium"] = 600] = "Medium";
    DrawerWidth[DrawerWidth["Wide"] = 968] = "Wide";
})(DrawerWidth || (DrawerWidth = {}));
var Fade = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  pointer-events: ", ";\n  background: ", ";\n  position: fixed;\n  top: 0;\n  left: ", ";\n  width: 100vw;\n  height: 100vh;\n  z-index: 100;\n  transition: background 0.35 ease-out;\n"], ["\n  pointer-events: ", ";\n  background: ", ";\n  position: fixed;\n  top: 0;\n  left: ", ";\n  width: 100vw;\n  height: 100vh;\n  z-index: 100;\n  transition: background 0.35 ease-out;\n"])), function (p) { return (p.active ? 'all' : 'none'); }, function (p) {
    return transparentize(p.active ? 0.3 : 1)(p.theme.colorPrimary);
}, SIDEBAR_WIDTH);
var DrawerView = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: ", "px;\n  overflow: hidden;\n  background: ", ";\n  transition: width 0.125s ease-out;\n  display: flex;\n  flex-direction: row;\n  height: 100vh;\n  position: absolute;\n  top: 0;\n  left: 0;\n"], ["\n  width: ", "px;\n  overflow: hidden;\n  background: ", ";\n  transition: width 0.125s ease-out;\n  display: flex;\n  flex-direction: row;\n  height: 100vh;\n  position: absolute;\n  top: 0;\n  left: 0;\n"])), function (p) { return (p.active ? p.width : 0); }, function (p) { return lighten(0.3)(p.theme.colorSecondary); });
var DrawerContent = styled.ul(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  list-style: none;\n  padding: 0.5rem 1rem;\n  padding-top: 4.5rem;\n  flex-grow: 1;\n  flex-shrink: 0;\n  flex-direction: column;\n  display: flex;\n  margin: 0;\n  overflow-x: hidden;\n  overflow-y: auto;\n"], ["\n  list-style: none;\n  padding: 0.5rem 1rem;\n  padding-top: 4.5rem;\n  flex-grow: 1;\n  flex-shrink: 0;\n  flex-direction: column;\n  display: flex;\n  margin: 0;\n  overflow-x: hidden;\n  overflow-y: auto;\n"])));
var DrawerSidebar = styled(Sidebar)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: ", ";\n"], ["\n  background: ", ";\n"])), function (p) { return p.theme.colorDrawerBackground; });
export var Drawer = function (p) {
    var onBackClick = p.onBackClick, viewProps = __rest(p, ["onBackClick"]);
    return (React.createElement(Fade, { onClick: onBackClick, active: p.active },
        React.createElement(DrawerView, __assign({}, viewProps),
            React.createElement(DrawerSidebar, null,
                React.createElement(GlobalItem, { icon: faShieldAlt, visualMode: VisualMode.Drawer }),
                React.createElement(Divider, null),
                React.createElement(GlobalItem, { icon: faLongArrowLeft, onClick: p.onBackClick, visualMode: VisualMode.Drawer })),
            React.createElement(DrawerContent, null, p.children))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
