var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { media } from '../media';
import styled from '../styled-components';
var Row = styled.ul(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  margin: 0;\n  padding: 0;\n  flex-grow: 1;\n  width: auto;\n  list-style: none;\n  ", "\n"], ["\n  display: flex;\n  flex-direction: column;\n  margin: 0;\n  padding: 0;\n  flex-grow: 1;\n  width: auto;\n  list-style: none;\n  ", "\n"])), media.minSm(templateObject_1 || (templateObject_1 = __makeTemplateObject(["flex-direction: row;"], ["flex-direction: row;"]))));
export var ItemSize;
(function (ItemSize) {
    ItemSize["Long"] = "long";
    ItemSize["Medium"] = "medium";
    ItemSize["Short"] = "short";
})(ItemSize || (ItemSize = {}));
var lengthForsize = function (size) {
    switch (size) {
        case ItemSize.Long:
            return 'auto';
        case ItemSize.Medium:
            return '50%';
        case ItemSize.Short:
            return '25%';
    }
};
var RowItem = styled.li(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  max-width: 100%;\n  flex-basis: auto;\n  margin: 0;\n  flex-grow: 1;\n  display: flex;\n  ", "\n"], ["\n  max-width: 100%;\n  flex-basis: auto;\n  margin: 0;\n  flex-grow: 1;\n  display: flex;\n  ", "\n"])), function (p) { return media.minSm(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    margin: 0 12px 0 0;\n    max-width: ", "\n    flex-basis: ", "\n  "], ["\n    margin: 0 12px 0 0;\n    max-width: ", "\n    flex-basis: ", "\n  "])), lengthForsize(p.size), lengthForsize(p.size)); });
export { Row, RowItem };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
