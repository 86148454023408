var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { transparentize } from 'polished';
import * as React from 'react';
import * as Actions from '../Actions';
import * as Indicators from '../Indicators';
import styled from '../styled-components';
var ToolbarView = styled.nav(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-bottom: 1px solid ", ";\n  height: 4rem;\n  display: flex;\n  flex-direction: row;\n  padding-bottom: 0.5rem;\n  margin-bottom: 1rem;\n"], ["\n  border-bottom: 1px solid ", ";\n  height: 4rem;\n  display: flex;\n  flex-direction: row;\n  padding-bottom: 0.5rem;\n  margin-bottom: 1rem;\n"])), function (p) { return transparentize(0.75)(p.theme.colorPrimary); });
var ToolbarItems = styled.ul(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  list-style: none;\n  margin: 0;\n  padding: 0.5rem 0;\n  display: flex;\n  flex-direction: row;\n  flex-grow: 1;\n"], ["\n  list-style: none;\n  margin: 0;\n  padding: 0.5rem 0;\n  display: flex;\n  flex-direction: row;\n  flex-grow: 1;\n"])));
export var Item = styled.li(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 0 0 0 ", ";\n  padding: 0;\n  display: flex;\n  flex-grow: ", ";\n"], ["\n  margin: 0 0 0 ", ";\n  padding: 0;\n  display: flex;\n  flex-grow: ", ";\n"])), function (p) { return (p.spreadLeft ? 'auto' : 0); }, function (p) { return (p.grow ? 1 : 0); });
var Breadcrumb = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: transparent;\n  border: transparent;\n  font-family: ", ";\n  color: ", ";\n  font-weight: 600;\n  font-size: 0.75rem;\n  margin: auto 10px auto 0;\n"], ["\n  background: transparent;\n  border: transparent;\n  font-family: ", ";\n  color: ", ";\n  font-weight: 600;\n  font-size: 0.75rem;\n  margin: auto 10px auto 0;\n"])), function (p) { return p.theme.fontFamilyBody; }, function (p) { return p.theme.colorPrimary; });
export var Breadcrumbs = function (_a) {
    var data = _a.data;
    return (React.createElement(Item, null, data.map(function (_a, index) {
        var label = _a.label, onClick = _a.onClick;
        return (React.createElement(Breadcrumb, { key: label + "-" + index, onClick: onClick, disabled: !onClick }, label));
    })));
};
export var View = function (_a) {
    var children = _a.children;
    return (React.createElement(ToolbarView, null,
        React.createElement(ToolbarItems, null, children)));
};
export var Renderer = function (_a) {
    var rightActions = _a.rightActions;
    return (React.createElement(View, null,
        React.createElement(Item, { spreadLeft: true },
            React.createElement(Actions.Group, null, rightActions.map(function (action, index) { return (React.createElement(React.Fragment, { key: "" + action.name + index }, action.loading ? (React.createElement(Actions.Toolbar, null,
                React.createElement(Indicators.Spinner, null))) : (React.createElement(Actions.Toolbar, { onClick: action.onClick },
                React.createElement(FontAwesomeIcon, { icon: action.icon }))))); })))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
