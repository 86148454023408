import * as React from 'react';
import { ErrorCode, ErrorItem, ErrorList, ErrorView } from './LoadError';
export var InternalError = function (_a) {
    var error = _a.error, extraInfo = _a.extraInfo;
    return (React.createElement(ErrorView, null,
        React.createElement("h2", null, "An error prevented this page from rendering:"),
        React.createElement(ErrorList, null,
            React.createElement(ErrorItem, null,
                React.createElement("strong", null, "Name:"),
                " ",
                error ? error.name : 'n/a'),
            React.createElement(ErrorItem, null,
                React.createElement("strong", null, "Message:"),
                " ",
                error ? error.message : 'n/a')),
        extraInfo ? (React.createElement(React.Fragment, null,
            React.createElement("h3", null, "Complete Stack Trace:"),
            React.createElement(ErrorCode, null, JSON.stringify(extraInfo)))) : null));
};
