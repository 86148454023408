var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { decamelize } from 'humps';
import * as React from 'react';
import styled, { remCalc } from '../styled-components';
var ErrorList = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  list-style: none;\n  padding: ", " ", ";\n  margin: 0;\n  display: flex;\n  flex: 0 0 auto;\n  flex-direction: column;\n"], ["\n  list-style: none;\n  padding: ", " ", ";\n  margin: 0;\n  display: flex;\n  flex: 0 0 auto;\n  flex-direction: column;\n"])), remCalc(8), remCalc(12));
var ErrorItem = styled.li(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: ", " 0;\n  display: flex;\n  flex-direction: row;\n  font-size: ", ";\n  font-family: ", ";\n"], ["\n  margin: ", " 0;\n  display: flex;\n  flex-direction: row;\n  font-size: ", ";\n  font-family: ", ";\n"])), remCalc(4), remCalc(12), function (p) { return p.theme.fontFamilyBody; });
var Icon = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  opacity: 0.75rem;\n  padding-right: 0.5rem;\n  display: block;\n  color: ", ";\n"], ["\n  opacity: 0.75rem;\n  padding-right: 0.5rem;\n  display: block;\n  color: ", ";\n"])), function (p) { return p.theme.colorErrorText; });
var renderError = function (error) {
    if (typeof error === 'object') {
        return Object.keys(error)
            .map(function (k) { return k + " " + error[k]; })
            .join(', ');
    }
    return error;
};
export function ErrorSummaryRenderer() {
    return function (props) {
        var errors = props.errors || {};
        return props.errors ? (React.createElement(ErrorList, null, Object.keys(props.errors || {}).map(function (field) { return (React.createElement(ErrorItem, { key: "error" + field },
            React.createElement(Icon, null,
                React.createElement(FontAwesomeIcon, { icon: faExclamationTriangle })),
            ' ',
            React.createElement("span", null,
                field.charAt(0).toUpperCase(),
                decamelize(field)
                    .split('_')
                    .join(' ')
                    .slice(1),
                ' ',
                ("" + renderError(errors[field])).toLowerCase()))); }))) : null;
    };
}
var templateObject_1, templateObject_2, templateObject_3;
