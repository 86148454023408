var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { keyframes } from '../styled-components';
// Create the keyframes
var rotate = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(1440deg);\n  }\n"], ["\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(1440deg);\n  }\n"
    // Here we create a component that will rotate everything we pass in over two seconds
])));
// Here we create a component that will rotate everything we pass in over two seconds
export var Rotate = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-grow: 0;\n  margin: auto;\n  animation: ", " 3s ease-in-out infinite;\n"], ["\n  display: flex;\n  flex-grow: 0;\n  margin: auto;\n  animation: ", " 3s ease-in-out infinite;\n"])), rotate);
var templateObject_1, templateObject_2;
