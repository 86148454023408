var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { ImagePreview } from './ImagePreview';
import { useNormalizedUploads } from './useNormalizedUploads';
import { concatStyles, remCalc } from 'utils';
import { DropTarget } from './DropTarget';
var THUMBLIST_STYLES = 'flex flex-grow flex-row my-auto mx-0 p-0 flex-wrap';
var INACTIVE_STYLES = 'border-contentAreaBorder';
var CONTAINER_STYLES = 'flex border content-center w-full';
var ACTIVE_STYLES = 'border-actionable';
var minHeight = remCalc(120);
export var Gallery = function (_a) {
    var accept = _a.accept, onUpdate = _a.onUpdate, onDelete = _a.onDelete, onUpload = _a.onUpload, maxFiles = _a.maxFiles, previews = _a.previews, getEndpointsForFilenames = _a.getEndpointsForFilenames, label = _a.label;
    var _b = useNormalizedUploads({
        previews: previews,
        onUpdate: onUpdate,
        onDelete: onDelete,
        onUpload: onUpload,
        maxFiles: maxFiles,
        accept: accept,
        getEndpointsForFilenames: getEndpointsForFilenames,
    }), dropzone = _b.dropzone, renderablePreviews = _b.renderablePreviews, uploads = __rest(_b, ["dropzone", "renderablePreviews"]);
    return (React.createElement("div", { className: "p-2 flex-col w-full rounded border border-primary-dark" },
        React.createElement("div", __assign({}, dropzone.getRootProps(), { className: concatStyles([
                CONTAINER_STYLES,
                dropzone.isDragActive ? ACTIVE_STYLES : INACTIVE_STYLES,
            ]), style: { minHeight: minHeight } }),
            React.createElement("input", __assign({}, dropzone.getInputProps())),
            renderablePreviews.length > 0 ? (React.createElement("ul", { className: THUMBLIST_STYLES }, renderablePreviews.map(function (preview, index) {
                return (React.createElement(ImagePreview, { primary: index === 0, onSort: uploads.handleSort, key: preview.id, preview: preview, onDelete: uploads.handleDeletedFile, index: index, id: preview.id, progress: uploads.progressForUpload(preview.id), onFinishSort: uploads.handleFinishSort }));
            }))) : (React.createElement(DropTarget, { label: label, active: dropzone.isDragActive })))));
};
